<template>
  <div>
  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'VPIGraph',

}
</script>

<style scoped lang="scss">

</style>
